<template>
  <div>

    <div class="flex ai-c fgap1 mb-2 mt-1">
      <div class="flex fe " style="flex: 0 1 65%">
          <h2 class="mr-2">Previsão Financeira</h2>
          <div class="flex ai-c text-sm">
            <a href="/" class="text-light text-thin router-link-active"> Portal dos Clientes </a>
            <span class="text-light mh-1">/</span>
          <p class="text-primary">Calendário Financeiro</p>
        </div>
      </div>
      <div class="flex ai-c fgap1 fw-w calendar-legends" style="flex: 0 1 35%">
        <div class="flex ai-c gap1 jc-c">
          <div class="legend-square" style="--bg: #137700; --border: #137700;"></div>
          <span>Numerário</span>
        </div>
        <div class="flex ai-c fgap1">
          <div class="legend-square" style="--bg: #ffffff; --border: #137700;"></div>
          <span>Numerário Previsto</span>
        </div>
        <div class="flex ai-c fgap1 jc-ge">
          <div class="legend-square" style="--bg: #104375; --border: #104375;"></div>
          <span>Parcela de Invoice</span>
        </div>
      </div>
    </div>

    <div class="card">
      <new-gs-calendar
        :week="isWeek"
        :content="filteredCalendarContent"
        @change="({ year, month, endDay }) => getProcessesMonth(year, month, endDay)"
        @changeWeek="({ start, end }) => getProcessWeek(start, end)"
      >
        <template #header="{ data }">
          <div class="flex ai-c jc-sb fgap1 mb-2">

            <div style="flex: 0 1 100%; display: flex; align-items: center; gap: 1rem">

              <div style="flex: 0 1 30%" class="flex ai-c fgap2 jc-gst">
                <drop-down contentPosition="left" label="Filtros" content-width="auto">
                  <template #content>
                    <div class="calendar-filters">
                      <div class="calendar-filter-col">
                        <h5>Visualização</h5>
                        <ul class="calendar-views-list">
                          <li>
                            <label>
                              Semanal
                              <input
                                type="radio"
                                name="calendarView"
                                id="calendarView"
                                :value="true"
                                v-model="isWeek"
                              />
                            </label>
                          </li>
                          <li>
                            <label>
                              Mensal
                              <input
                                type="radio"
                                name="calendarView"
                                id="calendarView"
                                :value="false"
                                v-model="isWeek"
                              />
                            </label>
                          </li>
                        </ul>
                      </div>

                      <div class="calendar-filter-col">
                        <h5>Tipo</h5>
                        <ul class="calendar-views-list">
                          <li>
                            <label>
                              Numerários
                              <input
                                type="checkbox"
                                name="numerarios"
                                id="numerarios"
                                value="NUMERARIO"
                                v-model="calendarFilters"
                              />
                            </label>
                          </li>
                          <li>
                            <label>
                              Invoices
                              <input
                                type="checkbox"
                                name="invoices"
                                id="invoices"
                                value="PARCELA_INVOICE"
                                v-model="calendarFilters"
                              />
                            </label>
                          </li>
                        </ul>
                      </div>

                      <div class="calendar-filter-col">
                        <h5>Status Numerário</h5>
                        <ul class="calendar-views-list">
                          <li>
                            <label>
                              Pago
                              <input
                                type="checkbox"
                                name="pago"
                                id="pago"
                                value="PAGO"
                                v-model="calendarStatus"
                              />
                            </label>
                          </li>
                          <li>
                            <label>
                              À Pagar
                              <input
                                type="checkbox"
                                name="a_pagar"
                                id="a_pagar"
                                value="A_PAGAR"
                                v-model="calendarStatus"
                              />
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </template>
                </drop-down>
                <drop-down
                  contentPosition="left"
                  label="Exibição"
                  content-width="auto"
                >
                  <template #content>
                    <div class="calendar-filters">
                      <div class="calendar-filter-col">
                        <h5>Tipo de Referência</h5>
                        <ul class="calendar-views-list">
                          <li>
                            <label>
                              Número da Invoice
                              <input
                                type="radio"
                                name="invoiceNumber"
                                id="invoiceNumber"
                                value="invoiceNumber"
                                v-model="calendarFieldView"
                              />
                            </label>
                          </li>
                          <li>
                            <label>
                              Sua Referência
                              <input
                                type="radio"
                                name="customerReference"
                                id="customerReference"
                                value="customerReference"
                                v-model="calendarFieldView"
                              />
                            </label>
                          </li>
                          <li>
                            <label>
                              Referência da Prime
                              <input
                                type="radio"
                                name="identification"
                                id="identification"
                                value="identification"
                                v-model="calendarFieldView"
                              />
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </template>
                </drop-down>
                <button type="button" class="btn-refresh" @click.prevent="refresh(data)">
                  <RefreshIcon :size="24" color="#104375" />
                </button>
              </div>
            <div style="flex: 0 1 40%" class="flex ai-c jc-c" v-if="!data.week">
              <button class="prev-month" @click.prevent="data.prevMonth()">
                <ChevronLeftIcon />
              </button>

              <h5 class="text-primary text-center text-md refresh" style="width: 200px" @click.prevent="refresh(data)">
                <span class="text-medium">{{ data.months[data.month] }}</span>
                {{ data.year }}
              </h5>

              <button class="next-month" @click.prevent="data.nextMonth()">
                <ChevronRightIcon />
              </button>
            </div>

            <div style="flex: 0 1 40%" class="flex ai-c jc-c" v-else>
              <button class="prev-month" @click.prevent="data.prevWeek()">
                <ChevronLeftIcon />
              </button>

              <div class="refresh" @click.prevent="refresh(data)">
                <p class="text-center text-primary text-md ph-2">
                  <span class="text-bold">
                    {{ data.weekData.start.getDate() }}
                    {{ months[data.weekData.start.getMonth()] }}
                    {{ String(data.weekData.start.getFullYear()).substring(2) }}
                  </span>
                  <span>Até</span>
                  <span class="text-bold">
                    {{ data.weekData.end.getDate() }}
                    {{ months[data.weekData.end.getMonth()] }}
                    {{ String(data.weekData.end.getFullYear()).substring(2) }}
                  </span>
                </p>
              </div>

              <button class="next-month" @click.prevent="data.nextWeek()">
                <ChevronRightIcon />
              </button>
            </div>

          <div style="flex: 0 1 30%" class="flex ai-c fgap2 jc-ge">
                <button v-if="userCanExportTxt" type="button" class="toggle-calendar-type-btn dark" @click.prevent="openExportModal('TXT_CASH_CALENDAR_RUDOLPH')">
                  GERAR TXT
                </button>

                <button type="button" class="toggle-calendar-type-btn dark" @click.prevent="openExportModal('EXCEL')">
                  GERAR EXCEL
                </button>
              </div>
            </div>
          </div>

          <div class="flex ai-c fgap1 jc-l mb-0" v-if="getResultsCount > 0">
            <div class="text-sm text-center mr-2">
              <p class="text-light text-thin">
                Estão sendo exibidos <span class="text-medium"> {{ getResultsCount }} lançamentos </span> com a previsão total de <span class="text-medium">{{ getResultsTotal }}</span>
              </p>
            </div>
          </div>
        </template>

        <template #item="{ past, future, active, content, index, date }">
          <new-gs-calendar-day-content
            :ref="index"
            :data="content"
            :past="past"
            :future="future"
            :active="active"
            :grouped="!isWeek"
            :date="date"
            :useField="calendarFieldView"
            @action="(item) => calendarItemClick(item, date)"
            @innerAction="(item) => openProcess(item)"
            @downloadAction="(item) => downloadDocument(item)"
          />
        </template>

        <template #itemFooter="{ content, overview }">
          <div v-if="content && !overview" class="text-right pv-2 ph-1">
            <p class="text-primary text-sm">TOTAL</p>
            <p class="text-primary text-bold">{{ getWeekTotal(content) }}</p>
          </div>

          <div v-if="!content && !overview" class="text-right pv-2 ph-1">
            <p class="text-primary text-sm">TOTAL</p>
            <p class="text-primary text-bold">R$ 0,00</p>
          </div>

          <div v-if="content && overview" class="text-right pv-2 ph-1">
            <p class="text-primary text-sm">Previsão da Semana</p>
            <p class="text-primary text-bold">{{ getWeekTotal(content) }}</p>
          </div>
        </template>

        <template #week="{ content }">
          <div
            v-if="content"
            class="text-right flex fd-c"
            :class="{
              'jc-c': !isWeek,
              'jc-fs': isWeek,
            }"
            style="height: 100%"
          >
            <p class="text-primary">TOTAL</p>
            <p class="text-primary text-bold mb-1">
              {{ getWeekTotal(content) }}
            </p>
            <p class="text-primary text-sm">Soma total de todos os processos na semana.</p>
          </div>
          <div
            v-else
            class="text-right flex fd-c"
            :class="{
              'jc-c': !isWeek,
              'jc-fs': isWeek,
            }"
            style="height: 100%"
          >
            <p class="text-primary">TOTAL</p>
            <p class="text-primary text-bold mb-1">R$ 0,00</p>
            <p class="text-primary text-sm">Soma total de todos os processos na semana.</p>
          </div>
        </template>

        <template #footer>
          <div class="flex jc-fe" v-if="!isWeek">
            <new-gs-calendar-item day="" noBorder :past="false" otherMonth>
              <div class="text-right flex fd-c jc-c" style="height: 100%">
                <p class="text-accent text-bold">Previsão Mensal</p>
              </div>
            </new-gs-calendar-item>

            <new-gs-calendar-item day="" noBorder :past="false" otherMonth>
              <div class="text-right flex fd-c jc-c" style="height: 100%">
                <p class="text-primary">TOTAL</p>
                <p class="text-primary text-bold mb-1">
                  {{ getResultsTotal }}
                </p>

                <p class="text-primary text-sm">Soma total de todos os processos do mês.</p>
              </div>
            </new-gs-calendar-item>
          </div>
        </template>
      </new-gs-calendar>
    </div>

    <transition name="calendar">
      <div @click.self.prevent="closeOverlay" class="modal-calendar" v-if="modalCalendarOpen">
        <div class="modal-calendar-container">
          <div class="modal-calendar-header">
            <div>
              <p class="text-sm text-natural text-normal">
                Dia: {{ selectedDayNumber }} / {{ selectedDay.length }} lançamentos
              </p>

              <h4 class="text-primary text-bold mb-1">Total: {{ getWeekTotal(selectedDay) }}</h4>
            </div>

            <button @click.prevent="closeOverlay" class="close-modal-calendar">X</button>
          </div>

          <div class="modal-calendar-content">
            <new-gs-calendar-day-content
              :date="selectedDate"
              :data="selectedDay"
              :grouped="false"
              @innerAction="(item) => openProcess(item)"
            />
          </div>
        </div>
      </div>
    </transition>

    <ModalProcess
      :data="modalData"
      :handler="modalProcessOpen"
      @request-close="closeProcessModal"
    />

    <Modal :handler="modalExportOpen" use-actions @request-close="modalExportOpen = false">
      <template #head> Configurações da exportação </template>

      <template #content>
        <div class="calendar-filters" style="min-width: unset; max-width: 900px; width: 100%">
          <div class="calendar-filter-col">
            <h5>Datas</h5>
            <ul class="calendar-views-list">
              <li>
                <label>
                  <span style="width: 80px">Início</span>
                  <DatePicker v-model="exportFilters.startDate" />
                </label>
              </li>
              <li>
                <label>
                  <span style="width: 80px">Fim</span>
                  <DatePicker v-model="exportFilters.endDate" />
                </label>
              </li>
            </ul>
          </div>

          <div class="calendar-filter-col">
            <h5>Tipo</h5>
            <ul class="calendar-views-list">
              <li>
                <label>
                  Todos
                  <input
                    type="radio"
                    name="numerarios"
                    id="numerarios"
                    value="TODOS"
                    v-model="exportFilters.category"
                  />
                </label>
              </li>

              <li>
                <label>
                  Numerários
                  <input
                    type="radio"
                    name="numerarios"
                    id="numerarios"
                    value="numerarios"
                    v-model="exportFilters.category"
                  />
                </label>
              </li>

              <li>
                <label>
                  Invoices
                  <input
                    type="radio"
                    name="invoices"
                    id="invoices"
                    value="invoices"
                    v-model="exportFilters.category"
                  />
                </label>
              </li>
            </ul>
          </div>

          <div class="calendar-filter-col">
            <h5>Status Numerário</h5>
            <ul class="calendar-views-list">
              <li>
                <label>
                  Todos
                  <input
                    type="radio"
                    name="pago"
                    id="pago"
                    value="TODOS"
                    v-model="exportFilters.status"
                  />
                </label>
              </li>
              <li>
                <label>
                  Pago
                  <input
                    type="radio"
                    name="pago"
                    id="pago"
                    value="PAGO"
                    v-model="exportFilters.status"
                  />
                </label>
              </li>
              <li>
                <label>
                  À Pagar
                  <input
                    type="radio"
                    name="a_pagar"
                    id="a_pagar"
                    value="A_PAGAR"
                    v-model="exportFilters.status"
                  />
                </label>
              </li>
            </ul>
          </div>
        </div>
      </template>

      <template #confirm-button>
        <a
          class="toggle-calendar-type-btn dark"
          href="#"
          @click.prevent="generateExport"
        >
          EXPORTAR
        </a>
      </template>
    </Modal>
  </div>
</template>

<script>
import NewGsCalendar from '@/components/NewGsCalendar.vue';
import ChevronLeftIcon from '@/components/Icons/ChevronLeftIcon.vue';
import ChevronRightIcon from '@/components/Icons/ChevronRightIcon.vue';
import RefreshIcon from '@/components/Icons/Refresh.vue';
import { mapActions, mapState } from 'vuex';
// eslint-disable-next-line import/no-cycle
import api from '@/services/api';
import NewGsCalendarItem from '@/components/NewGsCalendarItem.vue';
import { CalendarColumnsExcel } from '@/utils/ProcessesReportColumnsExcel';
import ModalProcess from '@/components/ModalProcess.vue';
import moment from 'moment/moment';
import Modal from '@/components/Modal.vue';
import DatePicker from '@/components/DataInput/DatePicker.vue';
import DropDown from '../components/DropDown.vue';
import NewGsCalendarDayContent from '../components/NewGsCalendarDayContent.vue';

export default {
  name: 'CalendarView',
  props: {
    pageTitle: {
      type: String,
      default: '',
    },
    breadcrumbName: {
      type: String,
      default: '',
    },
  },
  components: {
    Modal,
    DropDown,
    NewGsCalendarItem,
    ChevronRightIcon,
    ChevronLeftIcon,
    NewGsCalendar,
    RefreshIcon,
    ModalProcess,
    DatePicker,
    NewGsCalendarDayContent,
  },
  data() {
    return {
      modalExportOpen: false,
      modalExportType: 'EXCEL',
      modalCalendarOpen: false,
      fieldsExcel: CalendarColumnsExcel,
      exportFilters: {
        startDate: null,
        endDate: null,
        category: 'TODOS',
        status: 'TODOS',
      },
      modalProcessOpen: false,
      modalData: {},
      overlayProps: {
        left: 0,
        width: 0,
      },
      selectedDay: [],
      selectedDayNumber: null,
      selectedDate: null,
      selectedProcess: {},
      calendarContent: {},
      calendarFilters: ['NUMERARIO', 'PARCELA_INVOICE'],
      calendarFieldView: 'customerReference',
      calendarStatus: ['A_PAGAR', 'PAGO'],
      isWeek: false,
      months: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    };
  },
  methods: {
    ...mapActions(['toggleLoading']),
    ...mapActions('process', ['getProcessDocuments', 'getProcess']),
    openExportModal(type) {
      this.modalExportOpen = true;
      this.modalExportType = type;
    },
    async openProcess(item) {
      const ok = await this.getProcess({ identification: item.id });
      if (ok) {
        this.modalData = this.process;
        this.modalData.documents = await this.getProcessDocuments(item.id);
        this.modalProcessOpen = true;
      }
    },
    closeProcessModal() {
      this.modalProcessOpen = false;
      this.modalData = null;
    },
    async getProcessesMonth(year, month) {
      this.closeOverlay();

      if (this.user.companies) {
        await this.toggleLoading(true);

        const companiesIds = this.user.companies.map((c) => c.id).join(',');

        try {
          let response;

          if (!year || !month) {
            const date = new Date();
            const ld = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();

            const imonth = String(date.getMonth() + 1).padStart(2, '0');

            response = await api.get(`/api/public/customer/cash-calendar?customerIds=${companiesIds}&startDate=${date.getFullYear()}-${imonth}-01T00:00:00&endDate=${date.getFullYear()}-${imonth}-${ld}T23:59:59&type=SCREEN&category=TODOS`);
          } else {
            const imonth = String(month).padStart(2, '0');
            const ld = new Date(year, month, 0).getDate();

            response = await api.get(`/api/public/customer/cash-calendar?customerIds=${companiesIds}&startDate=${year}-${imonth}-01T00:00:00&endDate=${year}-${imonth}-${ld}T23:59:59&type=SCREEN&category=TODOS`);
          }

          this.calendarContent = response.data.data;
        } catch (e) {
          this.$toast.error(e.message);
        } finally {
          await this.toggleLoading(false);
        }
      }
    },
    async getProcessWeek(start, end) {
      if (this.user.companies) {
        await this.toggleLoading(true);

        const companiesIds = this.user.companies.map((c) => c.id).join(',');

        try {
          let response;

          const date = new Date();
          const startD = new Date(date.setDate(date.getDate() - date.getDay()));
          const endD = new Date(date.setDate(date.getDate() - date.getDay() + 6));

          if (!start || !end) {
            const iStart = `${startD.getFullYear()}-${String(startD.getMonth() + 1).padStart(2, '0')}-${String(startD.getDate()).padStart(2, '0')}T00:00:00`;
            const iEnd = `${endD.getFullYear()}-${String(endD.getMonth() + 1).padStart(2, '0')}-${String(endD.getDate()).padStart(2, '0')}T23:59:59`;

            response = await api.get(`/api/public/customer/cash-calendar?customerIds=${companiesIds}&startDate=${iStart}&endDate=${iEnd}&type=SCREEN&category=TODOS`);
          } else {
            const iStart = `${start.getFullYear()}-${String(start.getMonth() + 1).padStart(2, '0')}-${String(start.getDate()).padStart(2, '0')}T00:00:00`;
            const iEnd = `${end.getFullYear()}-${String(end.getMonth() + 1).padStart(2, '0')}-${String(end.getDate()).padStart(2, '0')}T23:59:59`;

            response = await api.get(`/api/public/customer/cash-calendar?customerIds=${companiesIds}&startDate=${iStart}&endDate=${iEnd}&type=SCREEN&category=TODOS`);
          }

          this.calendarContent = response.data.data;
        } catch (e) {
          this.$toast.error(e.message);
        } finally {
          await this.toggleLoading(false);
        }
      }
    },
    getWeekTotal(content) {
      const result = content.reduce((acc, cur) => acc + cur.value, 0);
      return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).format(result);
    },
    calendarItemClick(data, date) {
      if (!this.isWeek) {
        this.selectedDayNumber = String(date.getDate()).padStart(2, '0');
        this.selectedDate = date;
        this.selectedDay = data;
        this.modalCalendarOpen = true;
      }
    },
    closeOverlay() {
      this.modalProcessOpen = false;
      this.selectedDayNumber = null;
      this.selectedDay = [];
      this.modalCalendarOpen = false;
    },
    checkEscape(e) {
      if (e.keyCode === 27) {
        this.closeOverlay();
      }
    },
    async finishDownloadDocument(process) {
      await this.toggleLoading(true);
      const { document, logs } = process.documents[process.documents.length - 1];

      try {
        const response = await api.get(
          `/api/public/customer/processes/${process.id}/documents/${document.id}/download`,
          {
            responseType: 'blob',
          },
        );

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = window.document.createElement('a');
        link.href = url;
        link.setAttribute('download', document.originalFilename);
        window.document.body.appendChild(link);
        link.click();

        logs.push({
          entityName: 'Document',
          createdBy: 'Nome do Usuário',
          type: 'DOWNLOAD',
          created: moment(new Date()).format('YYYY-MM-DD\\THH:mm:ss.SSS'),
        });
      } catch (err) {
        this.$toast.error('Não foi possível fazer o download do arquivo!');
      } finally {
        await this.toggleLoading(false);
      }
    },
    async downloadDocument(process) {
      const { logs } = process.documents[process.documents.length - 1];

      if (logs.length > 0) {
        const logDate = moment(new Date(logs[logs.length - 1].created)).format(
          'DD/MM/YYYY à\\s HH:mm',
        );
        const { isConfirmed } = await this.$swal.fire({
          title: 'Este numerário já foi baixado anteriormente!',
          text: `O Usuário ${logs[logs.length - 1].createdBy} baixou o arquivo em ${logDate}`,
          icon: 'warning',
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonColor: 'var(--primary-color)',
          confirmButtonText: 'Baixar mesmo assim',
          cancelButtonText: 'Cancelar',
          cancelButtonColor: 'var(--danger-color)',
          showCloseButton: true,
        });

        if (isConfirmed) {
          await this.finishDownloadDocument(process);
        }
        return;
      }

      await this.finishDownloadDocument(process);
    },
    async generateExport() {
      await this.toggleLoading(true);

      if (
        !this.exportFilters.startDate
        || this.exportFilters.startDate == null
        || this.exportFilters.startDate === ''
      ) {
        await this.$toast.error('A data de início e de fim devem estar preenchidas!');
        await this.toggleLoading(false);
        return false;
      }

      if (
        !this.exportFilters.endDate
        || this.exportFilters.endDate == null
        || this.exportFilters.endDate === ''
      ) {
        await this.$toast.error('A data de início e de fim devem estar preenchidas!');
        await this.toggleLoading(false);
        return false;
      }

      const companiesIds = this.user.companies.map((c) => c.id).join(',');

      try {
        const startDateReq = `${this.exportFilters.startDate.split('T')[0]}T00:00:00`;
        const endDateReq = `${this.exportFilters.endDate.split('T')[0]}T23:59:59`;

        const response = await api.get('/api/public/customer/cash-calendar', {
          params: {
            customerIds: companiesIds,
            startDate: startDateReq,
            endDate: endDateReq,
            category: this.exportFilters.category,
            status: this.exportFilters.status,
            type: this.modalExportType,
          },
          responseType: 'blob',
        });

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `calendario-financeiro.${this.modalExportType === 'EXCEL' ? 'xlsx' : 'txt'}`);
        document.body.appendChild(link);
        link.click();
      } catch (e) {
        if (e.response) {
          if (e.response.data instanceof Blob) {
            const res = await e.response.data.text();
            const parsedRes = JSON.parse(res);
            await this.$toast.error(parsedRes.message);
          } else {
            await this.$toast.error(e.response.data.message);
          }
        } else {
          await this.$toast.error('Não foi possível exportar os dados.');
        }
      } finally {
        await this.toggleLoading(false);
      }

      return true;
    },
    refresh(data) {
      if (data.week) {
        this.getProcessWeek(data.weekData.start, data.weekData.end);
      } else {
        this.getProcessesMonth(data.year, data.month + 1);
      }
    },
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('process', ['process']),
    filteredCalendarContent() {
      const res = {};

      const dates = Object.keys(this.calendarContent);

      for (let i = 0; i < dates.length; i += 1) {
        const date = dates[i];
        res[date] = [];

        for (let j = 0; j < this.calendarContent[date].length; j += 1) {
          const item = this.calendarContent[date][j];

          if (this.calendarFilters.includes('PARCELA_INVOICE') && item.category === 'PARCELA_INVOICE') {
            res[date].push(item);
          }

          if (this.calendarFilters.includes('NUMERARIO') && item.category !== 'PARCELA_INVOICE') {
            res[date].push(item);
          }
        }
      }

      if (this.calendarStatus.length === 2) {
        return res;
      }

      if (this.calendarStatus.length === 0) {
        return [];
      }

      const resDates = Object.keys(res);

      for (let i = 0; i < resDates.length; i += 1) {
        const date = resDates[i];

        if (this.calendarStatus.includes('PAGO')) {
          if (res[date] && res[date].length > 0) {
            res[date] = res[date].filter((item) => item.cashRequestDone === true);
          }
        }

        if (this.calendarStatus.includes('A_PAGAR')) {
          if (res[date] && res[date].length > 0) {
            res[date] = res[date].filter((item) => item.cashRequestDone !== true);
          }
        }
      }

      return res;
    },
    getResultsTotal() {
      const content = [];
      let total = 0;
      for (let i = 0; i < Object.keys(this.filteredCalendarContent).length; i += 1) {
        const itemI = Object.values(this.filteredCalendarContent)[i];
        for (let j = 0; j < itemI.length; j += 1) {
          content.push(itemI[j]);
          console.log(`${itemI[j].identification} - ${itemI[j].value}`);
          // console.log(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(itemI[j].value));
          total += itemI[j].value;
        }
      }
      console.log('Total:', total);
      const result = content.reduce((acc, cur) => acc + cur.value, 0);
      return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).format(result);
    },
    getResultsCount() {
      let res = 0;

      for (let i = 0; i < Object.keys(this.calendarContent).length; i += 1) {
        res += Object.values(this.calendarContent)[i].length;
      }

      return res;
    },
    userCanExportTxt() {
      return this.user !== undefined && this.user.companies !== undefined && this.user.companies.some((company) => {
        if ([102, 2397].includes(company.id)) {
          return true;
        }
        return false;
      });
    },
  },
  filters: {
    moneyFilter(value) {
      return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).format(value);
    },
  },
  async mounted() {
    window.addEventListener('keyup', this.checkEscape);
    if (this.user.companies) {
      await this.getProcessesMonth();
    }
  },
  beforeDestroy() {
    window.removeEventListener('keyup', this.checkEscape);
  },
  watch: {
    user() {
      this.getProcessesMonth();
    },
  },
};
</script>

<style scoped>
.next-month,
.prev-month {
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: 2px solid var(--accent-color);
  color: var(--primary-color);
  cursor: pointer;
  width: 45px;
  height: 45px;
  border-radius: 50%;

  transition: var(--all-transition);
}

.refresh{
  cursor: pointer
}

.next-month:hover,
.prev-month:hover {
  background: var(--accent-color);
}

.btn-refresh{
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: 2px solid var(--accent-color);
  cursor: pointer;
  width: 45px;
  height: 45px;
  border-radius: 50%;

  transition: var(--all-transition);
}

.btn-refresh:hover {
  background: var(--accent-color);
}

.toggle-calendar-type-btn {
  padding: 1rem 1.5rem;
  font-family: Poppins, sans-serif;
  border-radius: 100px;
  background-color: #f4f7f9;
  color: var(--primary-color);
  font-weight: bold;
  border: 0;
  cursor: pointer;
  transition: var(--all-transition);
  text-transform: uppercase;
}

.toggle-calendar-type-btn:hover {
  background-color: var(--primary-color);
  color: #f4f7f9;
}

.calendar-overlay {
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 2;
}

.calendar-modal {
  position: absolute;
  top: 0;
  bottom: 1rem;
  z-index: 5;
  background-color: white;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 0.75rem;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>

<style>
.calendar-enter-active,
.calendar-leave-active {
  transition: var(--all-transition);
}

.calendar-enter,
.calendar-leave-to {
  opacity: 0;
}

.calendar-enter-to,
.calendar-leave {
  opacity: 1;
}

.calendar-views-list {
  border-radius: 10px;
  border: var(--input-border);
}

.calendar-views-list label {
  color: var(--primary-color);
  display: flex;
  font-weight: 400;
  font-size: 1rem;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0.25rem;
  height: 2.5rem;
  margin-bottom: 0;
}

.calendar-views-list li:not(:last-of-type) {
  border-bottom: var(--input-border);
}

.modal-calendar {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9999999;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}

.modal-calendar-container {
  background-color: white;
  border-radius: 10px;
  padding: 1rem;
  max-width: 300px;
  width: 100%;
  max-height: 90vh;
  overflow: visible;
}

.modal-calendar-container.w-35 {
  max-width: 35%;
  width: 100%;
}

.modal-calendar-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 1rem;
}

.close-modal-calendar {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 40px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--light-bg-color);
  border: 0;
  cursor: pointer;
  font-family: var(--body-font-family), 'sans-serif';
  font-weight: 900;
  transition: all 0.2s ease-in-out 0s;
  color: var(--primary-color);
}

.close-modal-calendar:hover {
  background-color: var(--primary-color);
  color: white;
}

.calendar-filters {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  min-width: 600px;
  overflow: hidden;
  padding: 1rem;
  gap: 1rem;
  border-radius: 10px;
  background-color: white;
}

.calendar-filter-col {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.calendar-filter-col h5 {
  font-weight: 600;
  font-size: 1rem;
  color: var(--primary-color);
}

.overlay-calendar-modal {
  position: absolute;
  inset: 0;
  z-index: 9999;
  background-color: white;
}

.calendar-legends > div {
  font-size: 0.5rem;
  flex: 0 0 calc(32% - 0.2rem) !important;
}

.calendar-legends .legend-square {
  background-color: var(--bg);
  border: 2px solid var(--border);
  width: 15px;
  height: 15px;
  border-radius: 5px;
}

@media screen and (max-width: 768px) {
  .calendar-filters {
    grid-template-columns: 1fr;
    min-width: unset;
  }
}
</style>
