<template>
  <div :class="['documents-card medium-border-radius', type === 'success' && 'offwhite-bg']">
    <div :class="['card-header p-2 text-center mb-2 medium-border-radius', type === 'danger' && 'danger-bg']">
      <h5 class="text-bold" v-if="type === 'success'">DOCUMENTOS ENVIADOS</h5>
      <h5 class="text-bold text-white" v-if="type === 'danger'">DOCUMENTOS NÃO ENVIADOS</h5>
      <p v-if="type === 'success'">Total de {{ docs.length }} documentos enviados</p>
      <p v-if="type === 'danger'" class="text-white">Faltam {{ missingDocs.filter((doc) => doc.status === 'missing').length }} documentos obrigatórios</p>
    </div>

    <div class="card-content ph-2">
      <ul class="files-list columns gap2" v-if="type === 'success'">
        <li class="flex ai-c jc-sb fgap2 pb-2 span-12" v-for="(doc, index) in docs" :key="index">
          <div class="flex ai-c fgap1">
            <span v-if="verifyDocIcon(doc.originalFilename) === 'pdf'">
              <img src="@/assets/img/pdf.png" width="60px" height="60px" alt="PDF">
            </span>
            <span v-if="verifyDocIcon(doc.originalFilename) === 'xml'">
              <img src="@/assets/img/xml.png" width="60px" height="60px" alt="XML">
            </span>
            <span v-if="verifyDocIcon(doc.originalFilename) === 'png'">
              <img src="@/assets/img/png.png" width="60px" height="60px" alt="PNG">
            </span>
            <span v-if="verifyDocIcon(doc.originalFilename) === 'jpeg'">
              <img src="@/assets/img/jpg.png" width="60px" height="60px" alt="JPEG">
            </span>
            <span v-if="verifyDocIcon(doc.originalFilename) === 'jpg'">
              <img src="@/assets/img/jpg.png" width="60px" height="60px" alt="JPG">
            </span>
            <span v-if="verifyDocIcon(doc.originalFilename) === 'xlsx'">
              <img src="@/assets/img/xls.png" width="60px" height="60px" alt="XLSX">
            </span>
            <span v-if="verifyDocIcon(doc.originalFilename) === 'general'">
              <img src="@/assets/img/archive.png" width="60px" height="60px" alt="ARQUIVO">
            </span>
            <div>
              <p class="text-bold">{{ doc.documentTypeName }}</p>
              <p class="doc-description">{{ doc.originalFilename }}</p>
            </div>
          </div>

          <button type="button" @click.prevent="() => downloadDocument(doc.id, doc.originalFilename)" class="btn solid primary medium text-thin">BAIXAR DOCUMENTO</button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import api from '@/services/api';

// import {
//   PdfIcon,
// } from '@/components/Icons';

export default {
  name: 'DocumentsCard',
  components: {
    // Icons
    // PdfIcon,
  },
  methods: {
    async downloadDocument(docId, originalFilename) {
      try {
        const response = await api.get(`/api/public/customer/processes/${this.process.id}/documents/${docId}/download`, {
          responseType: 'blob',
        });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', originalFilename);
        document.body.appendChild(link);
        link.click();
      } catch (err) {
        this.$toast.error('Não foi possível fazer o download do arquivo!');
      }
    },
    verifyDocIcon(originalFilename) {
      const reg = [
        {
          type: 'pdf',
          reg: new RegExp('.pdf', 'gi'),
        },
        {
          type: 'jpeg',
          reg: new RegExp('.jpeg', 'gi'),
        },
        {
          type: 'jpg',
          reg: new RegExp('.jpg', 'gi'),
        },
        {
          type: 'png',
          reg: new RegExp('.png', 'gi'),
        },
        {
          type: 'xml',
          reg: new RegExp('.xml', 'gi'),
        },
        {
          type: 'xlsx',
          reg: new RegExp('.xlsx', 'gi'),
        },
      ];

      let type = 'general';

      reg.forEach((r) => {
        if (r.reg.test(originalFilename)) {
          type = r.type;
        }
      });

      return type;
    },
  },
  props: {
    type: {
      type: String,
      default: 'success',
    },
    docs: {
      type: Array,
      default: () => [],
    },
    missingDocs: {
      type: Array,
      default: () => [],
    },
    process: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style scoped>
  .files-list:not(.missing) li:not(:last-of-type) {
    border-bottom: var(--input-border);
  }

  .files-list.missing li {
    border-bottom: var(--input-border);
  }

  .doc-description {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
</style>
